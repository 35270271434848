import React from "react";
import MainLayout from "../layouts/MainLayout";
import SearchBar from "../components/Search/SearchBar";
import StatsLinks from "../components/StatsLinks/StatsLinks";
import Hero from "../components/Hero/Hero";
import LatestNews from "../components/LatestNews/LatestNews";
import LatestProducts from "../components/LatestProducts/LatestProducts";
import IFrame from "../components/IFrame/IFrame";
import { useSelector } from 'react-redux';

export default function Home() {
    const currentTenant = useSelector((state) => state.currentTenant.tenant);

    return (
        <MainLayout>
            <div className="max-w-screen-3xl mx-auto w-full">
                <div className="flex-grow lg:p-10 p-3 sm:space-y-3 space-y-1.5">

                    <section className="flex md:flex-row items-start flex-col md:justify-between gap-x-12">
                        <header className="">
                            <p className="sm:text-base text-sm font-medium text-gray-600">Dashboard</p>
                            <h1 className="font-bold sm:text-2xl text-lg sm:mb-2 mb-1 whitespace-nowrap">Banana Moon Hub</h1>
                        </header>

                        <SearchBar
                            placeholder="Search"
                        />
                    </section>

                    <StatsLinks />


                    <section className="flex gap-4">
                        <div className="flex-1 space-y-6">
                            <Hero
                                title="The Midnight Moon Awards 2024"
                                description="Tickets are now live for this year's event!"
                                link={  'https://www.bananamoon-thehub.co.uk/shop/midnight-moon-awards/d3decec7481e5a8304d486e0967e2410' }
                                ctaTitle="Book your tickets"
                            />
                            <LatestNews />
                        </div>

                        <aside className="max-w-lg w-full lg:block hidden space-y-4 w-96">
                                <>
                                    {currentTenant?.dashboards?.map(data => (
                                        <IFrame
                                            key={ data.url}
                                            title={ data.name }
                                            reportUrl={ data.url }
                                        />
                                    ))}
                                </>
                            <LatestProducts />
                        </aside>
                    </section>
                </div>
            </div>
        </MainLayout>
    );
}