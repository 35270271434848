import { Link } from "react-router-dom";
import { 
    HomeIcon,
    TagIcon,
    StarIcon,
    NewspaperIcon,
    UsersIcon,
    UserGroupIcon,
    QuestionMarkCircleIcon,
    MapPinIcon,
    EnvelopeIcon,
    DocumentTextIcon,
    ArrowDownOnSquareIcon,
    ArrowTrendingUpIcon,
    MicrophoneIcon,
    TruckIcon,
    ChartPieIcon,
    BookOpenIcon,
    HandThumbUpIcon,
    ListBulletIcon,
    BuildingStorefrontIcon,
    ShoppingCartIcon,
    ShoppingBagIcon,
    CalendarDaysIcon,
    RectangleStackIcon,
    ChatBubbleLeftRightIcon,
    ChatBubbleBottomCenterIcon,
    PhotoIcon,
    VideoCameraIcon,
  } from '@heroicons/react/20/solid';
import { Badge } from "../Badge";
import classNames from "classnames";

export default function NavItem({icon, label, path, visible, secondaryIcon, v2, external}) {
    const fullPath = getPath(path, v2, external);
    const klass = 'grid grid-cols-[22px_1fr] items-center gap-x-2 px-4 py-3 hover:text-blue-primary text-sm'

    const linkMarkup = (
        <li className="NavItem">
            {v2 ? (
                <Link to={ fullPath } aria-label={ label } title={ label }  className={classNames(klass, {
                    "text-blue-primary": label.toLowerCase() == 'dashboard'
                })}>
    
                <div> { getIcon(icon)} </div>
                    <div className="flex items-center">
                        <span className="flex-1 inline-block truncate w-full max-w-[8rem]">{ label }</span>
                        {getIcon(secondaryIcon)}
                    </div>
        
            </Link>
            ) : <a data-atag={ true } aria-label={ label } title={ label } href={ fullPath } className={ klass }>
                    <div> { getIcon(icon)} </div>
                    <div className="flex items-center">
                        <span className="flex-1 inline-block truncate w-full max-w-[8rem]">{ label }</span>
                        { getIcon(secondaryIcon) }
                    </div>
                </a>}
        </li>
    );

    return visible && linkMarkup
}

function getPath(path, v2, externals) {
    if (externals || v2) {
        return path;
    }

   return  `${process.env.REACT_APP_V1_HOST}${path}`
}


// TODO: One day, move this to class-base and use css to add a background image
// which means, move svgs to a cdn.
function getIcon(icon) {
    // Switch case on icon type
    switch (icon) {
        case 'home':
        case 'marketing-log':
        case 'suppliers':
            return <HomeIcon className="w-5 h-5" />;
        case 'news':
            return <NewspaperIcon className="w-5 h-5" />;
        case 'calendar':
            return <CalendarDaysIcon className="w-5 h-5" />;

        case 'documents':
            return <RectangleStackIcon className="w-5 h-5" />;

        case 'documents-form':
            return <DocumentTextIcon className="w-5 h-5" />;

        case 'documents-log':
            return <ArrowDownOnSquareIcon className="w-5 h-5" />;

        case 'marketing':
            return <ChartPieIcon className="w-5 h-5" />;

        case 'marketing-form':
            return <ArrowTrendingUpIcon className="w-5 h-5" />;

        case 'awards':
            return <StarIcon className="w-5 h-5" />;

        case 'social':
        case 'tasks':
            return <ListBulletIcon className="w-5 h-5" />;

        case 'locations':
            return <MapPinIcon className="w-5 h-5" />;

        case 'topics':
            return <MicrophoneIcon className="w-5 h-5" />;

        case 'questions':
            return <QuestionMarkCircleIcon className="w-5 h-5" />;

        case 'users':
        case 'profile':
        case 'contacts':
        case 'lms':
            return <UsersIcon className="w-5 h-5" />;

        case 'user-groups':
            return <UserGroupIcon className="w-5 h-5" />;

        case 'envelope':
            return <EnvelopeIcon className="w-5 h-5" />;

        case 'forum':
            return <ChatBubbleLeftRightIcon className="w-5 h-5" />;

        case 'question':
            return <QuestionMarkCircleIcon className="w-5 h-5" />;

        case 'support':
            return <ChatBubbleBottomCenterIcon className="w-5 h-5" />;

        case 'feedback':
            return <HandThumbUpIcon className="w-5 h-5" />;

        case 'platform':
            return <BookOpenIcon className="w-5 h-5" />;

        case 'cart':
        case 'orders':
            return <ShoppingCartIcon className="w-5 h-5" />;

        case 'shop':
            return <BuildingStorefrontIcon className="w-5 h-5" />;

        case 'shop-list':
            return <TagIcon className="w-5 h-5" />;

        case 'delivery':
            return <TruckIcon className="w-5 h-5" />;

        case 'video':
            return <VideoCameraIcon className="w-5 h-5" />;

        case 'dock':
            return <PhotoIcon className="w-5 h-5" />;

        case 'badge':
           return <Badge count={ 10 } />

    
        default:
            return null;
    }
}